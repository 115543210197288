import React, { lazy, Suspense } from 'react';
import { Redirect } from 'react-router';
import { WebUrl, AccessRight, Role } from "../util/Constant";

/// <summary>
/// Author: -
/// </summary>
export const load = (Component: any) => (props: any) => (
    <Suspense fallback={<div className="loader-wrapper"></div>}>
        <Component {...props} />
    </Suspense>
)

const Login = load(lazy(() => import('./../pages/Login')));
const Dashboard = load(lazy(() => import('./../pages/Dashboard')));

// Admin Settings
const ManageRoles = load(lazy(() => import('../pages/roles/ManageRoles')));
const SystemSetting = load(lazy(() => import('../pages/admin/SystemSetting')));
const ApiLog = load(lazy(() => import('../pages/admin/ManageApiLog')));
const ActivityLog = load(lazy(() => import('../pages/admin/ManageActivityLog')));
const ApiRequestLog = load(lazy(() => import('../pages/admin/ManageApiRequestLog')));
const ManageCurrency = load(lazy(() => import('../pages/admin/ManageCurrency')));
const ManageBank = load(lazy(() => import('../pages/admin/ManageBank')));
const ManageUser = load(lazy(() => import('../pages/user/ManageUser')));
const UserDetail = load(lazy(() => import('../pages/user/UserDetail')));
const ManageCurrencyStock = load(lazy(() => import('../pages/stock/ManageCurrencyStock')));
const ManageTransaction = load(lazy(() => import('../pages/transaction/ManageTransaction')));
const CompanyReport = load(lazy(() => import('../pages/report/CompanyReport')));
const CompanyReportV2 = load(lazy(() => import('../pages/report/CompanyReportV2')));
const SalesReport = load(lazy(() => import('../pages/report/SalesReport')));
const SalesReportDetail = load(lazy(() => import('../pages/report/SalesReportDetail')));
const SalesReportSummary = load(lazy(() => import('../pages/report/SalesReportSummary')));
const CreditLimitReport = load(lazy(() => import('../pages/report/CreditLimitReport')));
const RecalculateTransaction = load(lazy(() => import('../pages/admin/RecalculateTransaction')));

/// <summary>
/// Author: -
/// </summary>
export const _LOGIN_ROUTE =
{
    path: WebUrl._URL_MAIN,
    exact: false,
    authRequired: false,
    component: () => <Redirect to={WebUrl._URL_LOGIN} />
}

export const _ROUTES = [
    {
        path: WebUrl._URL_LOGIN,
        exact: true,
        title: 'Login',
        authRequired: false,
        component: () => <Login />
    },
    {
        path: WebUrl._URL_MAIN,
        exact: true,
        authRequired: true,
        component: () => <Redirect to={WebUrl._URL_DASHBOARD} />
    },
    {
        path: WebUrl._URL_DASHBOARD,
        exact: true,
        authRequired: true,
        title: 'Dashboard',
        component: () => <Dashboard />
    },
    {
        path: WebUrl._URL_ROLES,
        exact: true,
        authRequired: true,
        role: [Role._SUPER_ADMIN],
        permission: { activity: AccessRight._COMPANY_PERMISSION, accessLevel: [AccessRight._VIEW_PERMISSION] },
        title: 'Roles Management',
        component: () => <ManageRoles />
    },
    {
        path: WebUrl._URL_SYSTEM_SETTING,
        exact: true,
        authRequired: true,
        role: [Role._SUPER_ADMIN, Role._COMPANY],
        permission: { activity: AccessRight._COMPANY_PERMISSION, accessLevel: [AccessRight._VIEW_PERMISSION] },
        title: 'System Setting',
        component: () => <SystemSetting />
    },
    {
        path: WebUrl._URL_API_LOG,
        exact: true,
        authRequired: true,
        role: [Role._SUPER_ADMIN, Role._COMPANY],
        title: 'Api Log',
        component: () => <ApiLog />
    },
    {
        path: WebUrl._URL_ACTIVITY_LOG,
        exact: true,
        authRequired: true,
        role: [Role._SUPER_ADMIN, Role._COMPANY],
        title: 'Activity Log',
        component: () => <ActivityLog />
    },
    {
        path: WebUrl._URL_API_REQUEST_LOG,
        exact: true,
        authRequired: true,
        role: [Role._SUPER_ADMIN, Role._COMPANY],
        title: 'Api Request Log',
        component: () => <ApiRequestLog />
    },
    {
        path: WebUrl._URL_MANAGE_CURRENCY,
        exact: true,
        authRequired: true,
        permission: { activity: AccessRight._CURRENCY_PERMISSION, accessLevel: [AccessRight._VIEW_PERMISSION] },
        role: [Role._SUPER_ADMIN, Role._COMPANY, Role._ADMIN],
        title: 'Manage Currency',
        component: () => <ManageCurrency />
    },
    {
        path: WebUrl._URL_MANAGE_BANK,
        exact: true,
        authRequired: true,
        role: [Role._SUPER_ADMIN, Role._COMPANY, Role._ADMIN],
        title: 'Manage Currency',
        component: () => <ManageBank />
    },
    {
        path: WebUrl._URL_ACCOUNT_DETAIL,
        exact: true,
        authRequired: true,
        role: [Role._SUPER_ADMIN, Role._COMPANY, Role._ADMIN, Role._SHAREHOLDER, Role._AGENT],
        title: 'Manage User',
        component: () => <UserDetail />
    },
    {
        path: WebUrl._URL_ADMIN_ACCOUNT_MANAGEMENT,
        exact: true,
        authRequired: true,
        role: [Role._SUPER_ADMIN, Role._COMPANY],
        title: 'Manage Admin',
        component: () => <ManageUser roleId={Role._ADMIN} />
    },
    {
        path: WebUrl._URL_SHAREHOLDER_ACCOUNT_MANAGEMENT,
        exact: true,
        authRequired: true,
        role: [Role._SUPER_ADMIN, Role._COMPANY, Role._ADMIN],
        title: 'Manage Shareholder',
        component: () => <ManageUser roleId={Role._SHAREHOLDER} />
    },
    {
        path: WebUrl._URL_AGENT_ACCOUNT_MANAGEMENT,
        exact: true,
        authRequired: true,
        role: [Role._SUPER_ADMIN, Role._COMPANY, Role._ADMIN, Role._SHAREHOLDER],
        title: 'Manage Agent',
        component: () => <ManageUser roleId={Role._AGENT} />
    },
    {
        path: WebUrl._URL_AGENT_DETAIL,
        exact: true,
        authRequired: true,
        role: [Role._SUPER_ADMIN, Role._COMPANY, Role._ADMIN, Role._SHAREHOLDER],
        title: 'Agent Detail',
        component: () => <UserDetail roleId={Role._AGENT} />
    },
    {
        path: WebUrl._URL_MEMBER_ACCOUNT_MANAGEMENT,
        exact: true,
        authRequired: true,
        role: [Role._SUPER_ADMIN, Role._COMPANY, Role._ADMIN, Role._SHAREHOLDER, Role._AGENT],
        title: 'Manage Member',
        component: () => <ManageUser roleId={Role._MEMBER} />
    },
    {
        path: WebUrl._URL_MEMBER_DETAIL,
        exact: true,
        authRequired: true,
        role: [Role._SUPER_ADMIN, Role._COMPANY, Role._ADMIN, Role._SHAREHOLDER, Role._AGENT, Role._MEMBER],
        title: 'Member Detail',
        component: () => <UserDetail roleId={Role._MEMBER}/>
    },
    {
        path: WebUrl._URL_SUB_ACCOUNT_MANAGEMENT,
        exact: true,
        authRequired: true,
        role: [Role._ADMIN],
        title: 'Manage Sub-Account',
        component: () => <ManageUser roleId={Role._SUB_ACCOUNT} />
    },
    {
        path: WebUrl._URL_MANAGE_COMPANY,
        exact: true,
        authRequired: true,
        role: [Role._SUPER_ADMIN],
        title: 'Manage Company',
        component: () => <UserDetail roleId={Role._COMPANY} />
    },
    {
        path: WebUrl._URL_MANAGE_CURRENCY_STOCK,
        exact: true,
        authRequired: true,
        permission: { activity: AccessRight._CURRENCY_STOCK_PERMISSION, accessLevel: [AccessRight._VIEW_PERMISSION] },
        role: [Role._SUPER_ADMIN, Role._COMPANY, Role._ADMIN],
        title: 'Manage Currency Stock',
        component: () => <ManageCurrencyStock />
    },
    {
        path: WebUrl._URL_MANAGE_TRANSACTION,
        exact: true,
        authRequired: true,
        permission: { activity: AccessRight._TRANSACTION_PERMISSION, accessLevel: [AccessRight._VIEW_PERMISSION] },
        title: 'Transaction',
        component: () => <ManageTransaction />
    },
    {
        path: WebUrl._URL_COMPANY_REPORT,
        exact: true,
        authRequired: true,
        permission: { activity: AccessRight._COMPANY_PERMISSION, accessLevel: [AccessRight._VIEW_PERMISSION] },
        title: 'Company Report',
        component: () => <CompanyReport />
    },
    {
        path: WebUrl._URL_COMPANY_REPORT_V2,
        exact: true,
        authRequired: true,
        permission: { activity: AccessRight._COMPANY_PERMISSION, accessLevel: [AccessRight._VIEW_PERMISSION] },
        title: 'Company Report 2',
        component: () => <CompanyReportV2 />
    },
    {
        path: WebUrl._URL_SALES_REPORT,
        exact: true,
        authRequired: true,
        permission: { activity: AccessRight._COMPANY_REPORT_PERMISSION, accessLevel: [AccessRight._VIEW_PERMISSION] },
        title: 'Sales Report',
        component: () => <SalesReport />
    },
    {
        path: WebUrl._URL_SALES_REPORT_DETAIL,
        exact: true,
        authRequired: true,
        permission: { activity: AccessRight._COMPANY_REPORT_PERMISSION, accessLevel: [AccessRight._VIEW_PERMISSION] },
        title: 'Sales Report Detail',
        component: () => <SalesReportDetail />
    },
    {
        path: WebUrl._URL_SALES_REPORT_SUMMARY,
        exact: true,
        authRequired: true,
        permission: { activity: AccessRight._COMPANY_REPORT_PERMISSION, accessLevel: [AccessRight._VIEW_PERMISSION] },
        title: 'Sales Report Summary',
        component: () => <SalesReportSummary />
    },
    {
        path: WebUrl._URL_CREDIT_LIMIT_REPORT,
        exact: true,
        authRequired: true,
        permission: { activity: AccessRight._SET_CREDIT_LIMIT_PERMISSION, accessLevel: [AccessRight._VIEW_PERMISSION] },
        title: 'Credit Limit Report',
        component: () => <CreditLimitReport />
    },
    {
        path: WebUrl._URL_RECALCULATE_TRANSACTION,
        exact: true,
        authRequired: true,
        permission: { activity: AccessRight._COMPANY_PERMISSION, accessLevel: [AccessRight._VIEW_PERMISSION] },
        title: 'Recalculate Transaction',
        component: () => <RecalculateTransaction />
    }
];

export default _ROUTES;
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import App from './app.jsx';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from "react-redux";
import store from "./ApplicationStore.js";
import './localization/i18n.js';
import { MantineProvider } from '@mantine/core';
import { createTheme, ThemeProvider, StyledEngineProvider } from "@mui/material/styles";

// css
import 'bootstrap/dist/css/bootstrap.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'react-quill/dist/quill.snow.css';
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/material.css';
import 'simple-line-icons/css/simple-line-icons.css';
import 'flag-icon-css/css/flag-icon.min.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import './index.css';
import './scss/react.scss';
import 'bootstrap-social/bootstrap-social.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import 'react-datetime/css/react-datetime.css';
import "react-datepicker/dist/react-datepicker.css";
import 'react-dual-listbox/lib/react-dual-listbox.css';

//js
import 'jquery/dist/jquery.min.js'
import 'bootstrap/dist/js/bootstrap.min.js'

// ========================================

const Main = React.lazy(() => import('../src/components/theme/Main'));

// breakpoints is available when using "material theme" that created by createTheme
const theme = createTheme();

/// <summary>
/// Author: -
/// </summary>
const Theme = ({ children }) => {
    const CHOSEN_THEME = process.env.REACT_APP_NAME;
    return (
        <>
            <React.Suspense fallback={<></>}>
                <Main />
            </React.Suspense>
            {children}
        </>
    )
}

ReactDOM.render(
    <Theme>
        <Provider store={store}>
            <BrowserRouter>
                <MantineProvider withGlobalStyles withNormalizeCSS theme={{
                    colors: {
                        purple: [
                            "#F7F4FA",
                            "#DDD2F0",
                            "#C3ADEC",
                            "#AA84F0",
                            "#9155FD",
                            "#8148E9",
                            "#743FD3",
                            "#6A3EB9",
                            "#62439B",
                            "#5B4484",
                            "#534371"
                        ],
                    },
                    primaryColor: 'purple',
                    primaryShade: 5
                }}>
                    <StyledEngineProvider injectFirst>
                        <ThemeProvider theme={theme}>
                            <App />
                        </ThemeProvider>
                    </StyledEngineProvider>
                </MantineProvider>
            </BrowserRouter>
        </Provider>
    </Theme>,
    document.getElementById('root')
);
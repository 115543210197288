import React from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { showMessage, numberWithCurrencyFormat } from "../../util/Util";
import { AlertTypes, Role } from "../../util/Constant";
import { useTranslation } from "react-i18next";
import { performLogout } from "../../redux/AuthAction";
import { Header, Text, MediaQuery, Burger, Group } from "@mantine/core";

/// <summary>
/// Author: Saitama
/// </summary>
const MantineHeader = (props) => {
  const { t } = useTranslation();
  const _userData = useSelector((state) => state.authState.userData);
  var _dispatch = useDispatch();
  var _history = useHistory();

  return (  
    <div className="header-sm">
      {/* <Header height={{ base: 50, md: 70 }} p="md" style={{ backgroundColor: '#e8ebef' }}> */}
      <Group>
        <MediaQuery largerThan="sm" styles={{ display: "none" }}>
          <Burger
            opened={props.opened}
            onClick={() => props.toogle()}
            size="sm"
            mr="xl"
          />
        </MediaQuery>
        <Text fw={700} fz='lg'>Mercury Pro</Text>
        {
          _userData?.userRoleId == Role._AGENT &&
            <div className='headercreditlimit'>
              <h5>{t("CREDIT_LIMIT")}: {_userData ? numberWithCurrencyFormat(_userData.creditLimit) : 0}</h5>
            </div>
        }
      </Group>
      {/* </Header> */}
    </div>
  );
};

export default MantineHeader;

import React from "react";
import { WebUrl, AccessRight, Role, TemporaryDisableFor } from "../../util/Constant";
import { Home, UserCircle, Users, UserCheck, Inbox, Dashboard, ReportMoney, ClipboardData, FileReport, ReportAnalytics } from "tabler-icons-react";

/// <summary>
/// Author: -
/// </summary>
const Menu = [
    {
        path: WebUrl._URL_DASHBOARD, title: 'HOME', isDummy: false, icon: <Home />
    },
    {
        title: 'ACCOUNTS', isDummy: true, role: [Role._SUPER_ADMIN, Role._COMPANY, Role._ADMIN, Role._SHAREHOLDER, Role._AGENT], icon: <Users />, 
        children: [
            { path: WebUrl._URL_ADMIN_ACCOUNT_MANAGEMENT, title: 'ADMIN', isDummy: false, permission: { activity: AccessRight._ADMIN_PERMISSION, accessLevel: [AccessRight._VIEW_PERMISSION] } },
            { path: WebUrl._URL_SHAREHOLDER_ACCOUNT_MANAGEMENT, title: 'SHAREHOLDER', isDummy: false, permission: { activity: AccessRight._SHAREHOLDER_PERMISSION, accessLevel: [AccessRight._VIEW_PERMISSION] } },
            { path: WebUrl._URL_AGENT_ACCOUNT_MANAGEMENT, title: 'AGENT', isDummy: false, permission: { activity: AccessRight._AGENT_PERMISSION, accessLevel: [AccessRight._VIEW_PERMISSION] } },
            { path: WebUrl._URL_MEMBER_ACCOUNT_MANAGEMENT, title: 'MEMBER', isDummy: false, permission: { activity: AccessRight._MEMBER_PERMISSION, accessLevel: [AccessRight._VIEW_PERMISSION] } },
            { path: WebUrl._URL_SUB_ACCOUNT_MANAGEMENT, title: 'SUB_ACCOUNT', isDummy: false, permission: { activity: AccessRight._MAIN_ACCOUNT_PERMISSION, accessLevel: [AccessRight._VIEW_PERMISSION] } }
        ]
    },
    {
        path: WebUrl._URL_MANAGE_TRANSACTION, title: 'TRANSACTION', isDummy: false, permission: { activity: AccessRight._TRANSACTION_PERMISSION, accessLevel: [AccessRight._VIEW_PERMISSION] }, icon: <FileReport />
    },
    // {
    //     path: WebUrl._URL_COMPANY_REPORT, title: 'COMPANY_REPORT', isDummy: false, permission: { activity: AccessRight._COMPANY_REPORT_PERMISSION, accessLevel: [AccessRight._VIEW_PERMISSION] }, icon: <ClipboardData />
    // },
    {
        path: WebUrl._URL_SALES_REPORT, title: 'SALES_REPORT', isDummy: false, permission: { activity: AccessRight._COMPANY_REPORT_PERMISSION, accessLevel: [AccessRight._VIEW_PERMISSION] }, icon: <ReportAnalytics />
    },
    {
        path: WebUrl._URL_SALES_REPORT_DETAIL, title: 'SALES_REPORT_DETAIL', isDummy: false, permission: { activity: AccessRight._COMPANY_REPORT_PERMISSION, accessLevel: [AccessRight._VIEW_PERMISSION] }, icon: <ClipboardData />
    },
    {
        path: WebUrl._URL_SALES_REPORT_SUMMARY, title: 'SALES_REPORT_SUMMARY', isDummy: false, permission: { activity: AccessRight._COMPANY_REPORT_PERMISSION, accessLevel: [AccessRight._VIEW_PERMISSION] }, icon: <ClipboardData />,
        role: [Role._SUPER_ADMIN, Role._COMPANY, Role._ADMIN, Role._SHAREHOLDER]
    },
    {
        path: WebUrl._URL_CREDIT_LIMIT_REPORT, title: 'CREDIT_LIMIT_REPORT', isDummy: false, permission: { activity: AccessRight._SET_CREDIT_LIMIT_PERMISSION, accessLevel: [AccessRight._VIEW_PERMISSION] }, icon: <ReportMoney />
    },
    {
        title: 'CONTROL_PANEL', isDummy: true, role: [Role._SUPER_ADMIN, Role._COMPANY, Role._ADMIN, Role._SUB_ACCOUNT], icon: <Dashboard />, 
        children: [
            { path: WebUrl._URL_MANAGE_COMPANY, title: 'MANAGE_COMPANY', role: [Role._SUPER_ADMIN], permission: { activity: AccessRight._COMPANY_PERMISSION, accessLevel: [AccessRight._VIEW_PERMISSION] } },
            { path: WebUrl._URL_MANAGE_CURRENCY, title: 'MANAGE_CURRENCY', permission: { activity: AccessRight._CURRENCY_PERMISSION, accessLevel: [AccessRight._VIEW_PERMISSION] } },
            { path: WebUrl._URL_MANAGE_CURRENCY_STOCK, title: 'MANAGE_CURRENCY_STOCK', permission: { activity: AccessRight._CURRENCY_STOCK_PERMISSION, accessLevel: [AccessRight._VIEW_PERMISSION] } },
            { path: WebUrl._URL_MANAGE_BANK, title: 'MANAGE_BANK', permission: { activity: AccessRight._BANK_PERMISSION, accessLevel: [AccessRight._VIEW_PERMISSION] } },
            { path: WebUrl._URL_SYSTEM_SETTING, title: 'SYSTEM_SETTING', permission: { activity: AccessRight._COMPANY_PERMISSION, accessLevel: [AccessRight._VIEW_PERMISSION] } },
            { path: WebUrl._URL_ACTIVITY_LOG, title: 'ACTIVITY_LOG', permission: { activity: AccessRight._COMPANY_PERMISSION, accessLevel: [AccessRight._VIEW_PERMISSION] } },
            { path: WebUrl._URL_API_LOG, title: 'API_LOG', permission: { activity: AccessRight._COMPANY_PERMISSION, accessLevel: [AccessRight._VIEW_PERMISSION] } },
            { path: WebUrl._URL_API_REQUEST_LOG, title: 'API_REQUEST_LOG', permission: { activity: AccessRight._COMPANY_PERMISSION, accessLevel: [AccessRight._VIEW_PERMISSION] } },
            { path: WebUrl._URL_RECALCULATE_TRANSACTION, title: 'RECALCULATE_TRANSACTION', permission: { activity: AccessRight._COMPANY_PERMISSION, accessLevel: [AccessRight._VIEW_PERMISSION] } }
        ]
    }
]

export default Menu;

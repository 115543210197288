/// <summary>
/// Author : -
/// API related key or field when making ajax or api call
/// </summary>
export class ApiKey {
    static _API_SUCCESS_KEY = "success";
    static _API_MESSAGE_KEY = "message";
    static _API_DATA_KEY = "data";
    static _API_CODE_KEY = "code";
    static _API_CONTENT_TYPE = 'Content-Type';
    static _API_ACCEPT = "Accept";
    static _API_APPLICATION_JSON = "application/json";
    static _API_XML_HTTP_REQUEST_KEY = "XMLHttpRequest";
    static _API_FORM_URLENCODED = "application/x-www-form-urlencoded";
    static _API_POST = "POST";
    static _API_GET = "GET";

    static _API_INTERNAL_ERROR_CODE = 500;
    static _API_TEMPORARRY_REDIRECT_CODE = 307;
}

/// <summary>
/// Author : -
/// Url to communicate to controller
/// </summary> 
export class ApiUrl {
    static _API_LOGIN = "/Account/Login";
    static _API_LOGOUT = "/Account/Logout";
    static _API_IS_LOGGED_IN = "/Account/IsLoggedIn";
    static _API_GET_ROLE_ACCESS_DATA = "/Account/GetRoleAccessData";
    static _API_GET_ROLE_BY_ID = "/Account/GetRoleById";
    static _API_GET_FIGHT_LIMIT_RELATED_DATA = "/Account/GetFightLimitRelatedData";
    static _API_UPDATE_USER_EAT_LIMIT = "/Account/UpdateUserEatLimit";
    static _API_CHANGE_PASSWORD = "/Account/ChangePassword";
    static _API_AGREE_TERMS_AND_CONDITIONS = "/Account/AgreeTermsAndConditions";
    static _API_GET_COMPANY_DETAIL_BY_SUPERADMIN = "/Account/GetCompanyDetailBySuperAdmin";

    // Account Management
    static _API_CREATE_OR_UPDATE_ACCOUNT = "/Account/CreateOrUpdateUser";
    static _API_CREATE_OR_UPDATE_SUB_ACCOUNT = "/Account/CreateOrUpdateSubAccount";
    static _API_GET_USER_ACTIVITY_PERMISSION = "/Account/GetUserActivityPermission";
    static _API_GET_AGENT_CURRENCIES = "/Account/GetAgentCurrencies";
    static _API_GET_CURRENCY_ASSIGNMENT = "/Account/GetCurrencyAssignment";

    static _API_GET_API_LOG = "/Configuration/GetApiLog";
    static _API_GET_API_REQUEST_LOG = "/Configuration/GetApiRequestLog";
    static _API_GET_USERS_ROLE = "/Setting/GetRoles";
    static _API_GET_ACTIVITIES = "/Setting/GetActivities";
    static _API_GET_PERMISSIONS = "/Setting/GetPermissions";
    static _API_GET_ROLE_PERMISSION = "/Setting/GetActivityPermissionSetting";
    static _API_GET_ROLE_ACTIVITY_PERMISSION = "/Setting/GetRoleActivityPermission";
    static _API_GET_SYSTEM_SETTING = "/Configuration/GetSystemSetting";
    static _API_UPDATE_SYSTEM_SETTING = "/Configuration/UpdateSystemSetting";

    static _API_SUBMIT_ASSIGN_ROLES = "/Setting/AssignRoles";
    static _API_SUBMIT_CREATE_UPDATE_ROLE = "/Setting/CreateOrUpdateRole";

    static _API_GET_USER_DETAIL_BY_ID = "/Account/GetUserDetailsById";
    static _API_GET_USER_DETAIL_BY_USERNAME = "/Account/GetUserDetailByUsername";
    static _API_DELETE_USER = "/Setting/DeleteUser";

    static _API_GET_CURRENCY = "/Configuration/GetCurrency";
    static _API_CREATE_OR_UPDATE_CURRENCY = "/Configuration/CreateOrUpdateCurrency";

    static _API_GET_BANK = "/Configuration/GetBank";
    static _API_CREATE_OR_UPDATE_BANK = "/Configuration/CreateOrUpdateBank";

    static _API_GET_COMPANY = "/Account/GetCompany";
    static _API_CREATE_OR_UPDATE_COMPANY = "/Account/CreateOrUpdateCompany";
    static _API_GET_USER_LISTING = "/Account/GetUsers";
    static _API_GET_USER_DETAIL = "/Account/GetUserDetailInit";
    static _API_CREATE_OR_UPDATE_ADMIN = "/Account/CreateOrUpdateAdmin";
    static _API_CREATE_OR_UPDATE_SHAREHOLDER = "/Account/CreateOrUpdateShareholder";
    static _API_GET_SUB_ACCOUNT = "/Account/GetSubAccountListing";
    static _API_CREATE_OR_UPDATE_AGENT = "/Account/CreateOrUpdateAgent";
    static _API_CREATE_OR_UPDATE_MEMBER = "/Account/CreateOrUpdateMember";
    static _API_CREATE_OR_UPDATE_SUB_ACCOUNT = "/Account/CreateOrUpdateSubAccount";
    static _API_GET_CURRENCY_STOCK = "/Configuration/GetCurrenyStock";
    static _API_CREATE_OR_UPDATE_CURRENCY_STOCK = "/Configuration/CreateOrUpdateCurrencyStock";
    static _API_UPDATE_CURRENCY_STOCK_STATUS = "/Configuration/UpdateCurrencyStockStatus";
    static _API_DELETE_CURRENCY_STOCK = "/Configuration/DeleteCurrencyStock";
    static _API_GET_ACTIVITY_LOG = "/Configuration/GetActivityLog";

    static _API_CREATE_OR_UPDATE_TRANSACTION = "/Transaction/CreateOrUpdateTransaction";
    static _API_GET_AGENT_TRANSACTION = "/Transaction/GetAgentTransaction";
    static _API_GET_COMPANY_REPORT = "/Transaction/GetCompanyReport";
    static _API_GET_SALES_REPORT = "/Transaction/GetSalesReport";
    static _API_GET_SALES_REPORT_DETAIL = "/Transaction/GetSalesReportDetail";
    static _API_GET_SALES_REPORT_SUMMARY = "/Transaction/GetSalesReportSummary";
    static _API_GET_CURRENCY_STOCK_TRANSACTION = "/Transaction/GetCurrencyStockTransaction";
    static _API_GET_DAILY_TRANSACTION_SUMMARY = "/Transaction/GetDailyTransactionSummary";
    static _API_GET_DAILY_TRANSACTION_SUMMARY_BY_CURRENCY = "/Transaction/GetDailyTransactionSummaryByCurrency";
    static _API_ADJUST_CREDIT_LIMIT = "/Transaction/AdjustCreditLimit";
    static _API_ADJUST_UPPER_CREDIT_LIMIT = "/Transaction/AdjustUpperCreditLimit";
    static _API_GET_CREDIT_LIMIT_TRANSACTION = "/Transaction/GetCreditLimitTransactions";
    static _API_RECALCULATE_TRANSACTION = "/Transaction/RecalculateTransaction";
}

/// <summary>
/// Author : -
/// Url to navigate to pages
/// </summary>
export class WebUrl {
    static _URL_MAIN = "/";
    static _URL_LOGIN = "/login";
    static _URL_DASHBOARD = "/dashboard";
    static _URL_ADMIN_ACCOUNT_MANAGEMENT = "/account/manage-admin";
    static _URL_SHAREHOLDER_ACCOUNT_MANAGEMENT = "/account/manage-shareholder";
    static _URL_AGENT_ACCOUNT_MANAGEMENT = "/account/manage-agent";
    static _URL_AGENT_DETAIL = "/account/agent-detail";
    static _URL_MEMBER_ACCOUNT_MANAGEMENT = "/account/manage-member";
    static _URL_MEMBER_DETAIL = "/account/member-detail";
    static _URL_SUB_ACCOUNT_MANAGEMENT = "/account/manage-sub-account";
    static _URL_ACCOUNT_DETAIL = "/account/detail";
    static _URL_SUB_ACCOUNT_DETAIL = "/sub-account/detail";
    static _URL_SYSTEM_SETTING = "/admin-setting/system-setting";
    static _URL_API_LOG = "/api-log";
    static _URL_ACTIVITY_LOG = "/activity-log";
    static _URL_API_REQUEST_LOG = "/api-request-log";
    static _URL_MANAGE_CURRENCY = "/admin/manage-currency";
    static _URL_ROLES = "/admin/manage-roles";
    static _URL_MANAGE_BANK = "/admin/manage-bank";
    static _URL_MANAGE_COMPANY = "/admin/manage-company";
    static _URL_MANAGE_CURRENCY_STOCK = "/manage-currency-stock";
    static _URL_MANAGE_TRANSACTION = "/transaction";
    static _URL_COMPANY_REPORT = "/company-report"
    static _URL_COMPANY_REPORT_V2 = "/company-report-2"
    static _URL_SALES_REPORT = "/sales-report";
    static _URL_SALES_REPORT_DETAIL = "/sales-report-detail";
    static _URL_SALES_REPORT_SUMMARY = "/sales-report-summary";
    static _URL_CREDIT_LIMIT_REPORT = "/credit-limit-report";
    static _URL_RECALCULATE_TRANSACTION = "/recalculate-transaction";
}

/// <summary>
/// Author : -
/// </summary>
export class Status {
    static _ENABLED = 1;
    static _DISABLED = 0;
    static _DELETED = 2;
}

/// <summary>
/// Author: -
/// this value needed to be tally to value in server, do not change recklessly
/// </summary> 
export class AccessRight {
    static _MAIN_ACCOUNT_PERMISSION = "Main Account";
    static _COMPANY_PERMISSION = "Company";
    static _ADMIN_PERMISSION = "Admin";
    static _SHAREHOLDER_PERMISSION = "Shareholder";
    static _AGENT_PERMISSION = "Agent";
    static _MEMBER_PERMISSION = "Member";
    static _CURRENCY_PERMISSION = "Currency";
    static _BANK_PERMISSION = "Bank";
    static _CURRENCY_STOCK_PERMISSION = "Currency Stock";
    static _TRANSACTION_PERMISSION = "Transaction";
    static _COMPANY_REPORT_PERMISSION = "Company Report";
    static _AGENT_REPORT_PERMISSION = "Agent Report";
    static _MEMBER_REPORT_PERMISSION = "Member Report";
    static _SET_CREDIT_LIMIT_PERMISSION = "Set Credit Limit";
    static _DASHBOARD_PERMISSION = "Dashboard";

    static _VIEW_PERMISSION = "1";
    static _CREATE_PERMISSION = "2";
    static _EDIT_PERMISSION = "3";
    static _DELETE_PERMISSION = "4";
}

/// <summary>
/// Author : -
/// </summary>
export class Language {
    static _CHINESE = "cn";
    static _ENGLISH = "en";
    static _BAHASA_MELAYU = "bm";
}

/// <summary>
/// Author : -
/// </summary>
export class SessionKey {
    static _LANGUAGE = "language";
    static _LOGIN_GUID = "loginGuid";
}

/// <summary>
/// Author: -
/// </summary>
export class InputValidationKey {
    static _REQUIRED_KEY = "required";
    static _MAX_LENGTH_KEY = "maxLength";
    static _MIN_LENGTH_KEY = "minLength";
    static _MIN_KEY = "min";
    static _MAX_KEY = "max";
    static _PATTERN = "pattern";
}

/// <summary>
/// Author: -
/// </summary>
export class AlertTypes {
    static _DEFAULT = 'default';
    static _INFO = 'info';
    static _SUCCESS = 'success';
    static _WARNING = 'warning';
    static _DANGER = 'danger';
    static _ERROR = 'error';
    static _INPUT = 'input';
    static _CUSTOM = 'custom';
}

/// <summary>
/// Author: -
/// </summary>
export class ValidationPattern {
    static _emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    static _phoneNumberRegex = /^(\+?6?01)[0|1|2|3|4|6|7|8|9]\-*[0-9]{7,8}$/;
    static _numberRegex = /^([1-9]|[1-9][0-9]|[1-9][0-9]*)$/;
    static _decimalRegex = /^[1-9][0-9]*(\.\d{1,2})?$|^[0](\.\d{1,2})?$/;
    static _digitRegex = /^\d+$/;
    static _twoDecimalAndLargerThanZero = /^(?!00?\.00$)\d{1,2}(?:\.\d{1,2})?$/;
}

/// <summary>
/// Author: -
/// </summary>
export const _SELECT_STATUS_OPTIONS = [
    { value: false, label: "DISABLED" },
    { value: true, label: "ENABLED" }
]

/// <summary>
/// Author: -
/// </summary>
export class Role {
    static _SUB_ACCOUNT = -1;
    static _SUPER_ADMIN = 1;
    static _COMPANY = 2;
    static _SHAREHOLDER = 3;
    static _ADMIN = 4;
    static _AGENT = 5;
    static _MEMBER = 6;
}

/// <summary>
/// Author: -
/// </summary>
export class LocalStorageKey {
}

/// <summary>
/// Author: -
/// </summary>
export const _LOCAL_STORAGE_DISPATCH = [

]

/// <summary>
/// Author: -
/// </summary>
export const _SELECT_DEBOUNCE_MS = 50;

/// <summary>
/// Author: CK
/// </summary>
export class TransactionStatus {
    static _PENDING = 0;
    static _APPROVED = 1;
    static _REJECTED = 2;
    static _ON_HOLD = 3;
    static _PENDING_AGENT_APPROVAL = 4;
}

/// <summary>
/// Author: CK
/// </summary>
export const _SELECT_TRANSACTION_STATUS_OPTIONS = [
    { value: TransactionStatus._PENDING_AGENT_APPROVAL, label: "PENDING_AGENT_APPROVAL" },
    { value: TransactionStatus._PENDING, label: "PENDING" },
    { value: TransactionStatus._APPROVED, label: "APPROVED" },
    { value: TransactionStatus._REJECTED, label: "REJECTED" },
    { value: TransactionStatus._ON_HOLD, label: "ON_HOLD" }
]

/// <summary>
/// Author: CK
/// </summary>
export class RateMethod {
    static _MULTIPLY = 0;
    static _DIVIDE = 1;
}

/// <summary>
/// Author: Rock
/// </summary>
export const _PAGINATE_PAGE_SIZE = 10;

/// <summary>
/// Author: Rock
/// </summary>
export class MathOperator {
    static _ADDITION = 1;
    static _SUBTRACTION = 2;
    static _MULTIPLY = 3;
    static _DIVIDE = 4;
}

/// <summary>
/// Author: -
/// </summary>
export class MimeTypes {
    static _PDF = 'application/pdf';
    static _JPEG = 'image/jpeg';
    static _JPG = 'image/jpg';
    static _PNG = 'image/png';
    static _GIF = 'image/gif';
    static _BMP = 'image/bmp';
}

/// <summary>
/// Author: -
/// </summary>
export class FileTypes {
    static _PDF = 'pdf';
    static _JPEG = 'jpeg';
    static _JPG = 'jpg';
    static _PNG = 'png';
    static _GIF = 'gif';
    static _BMP = 'bmp';
}

/// <summary>
/// Author: Rock
/// </summary>
export class EarnUnitType {
    static _RATE_BASED = 1;
    static _PERCENTAGE_BASED = 2;
}

/// <summary>
/// Author: YJ
/// </summary>
export class CreditLimitTransactionType {
    static _ADJUST = 1;
    static _USED = 2;
    static _REFUND = 3;
}

/// <summary>
/// Author: YJ
/// </summary>
export const _SELECT_CREDIT_LIMIT_TXN_TYPE = [
    { value: CreditLimitTransactionType._ADJUST, label: "SET_CREDIT_LIMIT" },
    { value: CreditLimitTransactionType._USED, label: "OPEN_TRANSACTION" },
    { value: CreditLimitTransactionType._REFUND, label: "CANCEL_TRANSACTION" },
]

/// <summary>
/// Author: Rock
/// </summary>
export const _RATE_DP = 4;
import React, { useState, useEffect } from "react";
import { useHistory, useLocation, Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { stringIsNullOrEmpty, showMessage, navigateTo, numberWithCurrencyFormat } from '../../util/Util'
import { AccessRight, AlertTypes, Role } from "../../util/Constant";
import { useTranslation } from 'react-i18next';
import { performLogout } from '../../redux/AuthAction';
import { Navbar, Group, Text, UnstyledButton, Collapse, Popover, Box, Stack, Divider, ActionIcon, Button } from '@mantine/core';
import { ChevronDown, ChevronRight, Password, Logout, Settings } from "tabler-icons-react";
import { SessionKey, Language, ApiKey, ApiUrl } from "../../util/Constant";
import Cookies from "universal-cookie";
import Menu from '../top-menu/menu.jsx';
import _ from 'lodash';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { useForm, Controller } from 'react-hook-form';
import Input from '../../components/custom/Input';
import ApiEngine from '../../util/ApiEngine';

/// <summary>
/// Author: Saitama
/// </summary>
const Sidebar = ({ opened, toogle }) => {
    const { t, i18n } = useTranslation();
    var _dispatch = useDispatch();
    var _history = useHistory();
    const _userData = useSelector(state => state.authState.userData);
    const _currentLocation = useLocation();
    const [filteredMenu, setFilteredMenu] = useState([]);
    const [targetCollapseMenu, setTargetCollapseMenu] = useState('');
    const [changePassword, setChangePassword] = useState(false);
    const { control, handleSubmit, errors } = useForm();
    var _cookies = new Cookies();
    const _LANGUAGE_OPTION = [
        {
            title: "English",
            prefix: "EN",
            value: Language._ENGLISH,
            image: require('../../assets/img/language-flag/en.png')
        },
        {
            title: "简体中文",
            prefix: "中文",
            value: Language._CHINESE,
            image: require('../../assets/img/language-flag/cn.png')
        },
        {
            title: "Melayu",
            prefix: "MY",
            value: Language._BAHASA_MELAYU,
            image: require('../../assets/img/language-flag/my.png')
        },
    ];

    const _LANGUAGE_MAPPING = {};
    _LANGUAGE_MAPPING[Language._ENGLISH] = "en";
    _LANGUAGE_MAPPING[Language._CHINESE] = "zh";
    _LANGUAGE_MAPPING[Language._BAHASA_MELAYU] = "ms";

    /// <summary>
    /// Author: Saitama
    /// </summary>
    useEffect(() => {
        if (!stringIsNullOrEmpty(_userData.accessString)) {
            filterMenuByPermission();
        }
    }, [_userData])

    /// <summary>
    /// Author: -
    /// </summary>
    useEffect(() => {
        onChangeLanguage((localStorage.getItem(SessionKey._LANGUAGE) ?? Language._ENGLISH));
    }, []);

    /// <summary>
    /// Author: -
    /// </summary>
    function onChangeLanguage(selectedLanguage) {
        i18n.changeLanguage(selectedLanguage);
        localStorage.setItem(SessionKey._LANGUAGE, selectedLanguage);
        _cookies.set(SessionKey._LANGUAGE, _LANGUAGE_MAPPING[selectedLanguage]);
    }

    /// <summary>
    /// Author: Saitama
    /// </summary>
    const filterMenuByPermission = () => {
        var menuToUse = _.cloneDeep(Menu);
        var userPermissionData = _userData.accessString.split(";");
        var permissionArrKey = [];

        for (var i = 0; i < userPermissionData.length; i++) {
            var keyValuePermission = userPermissionData[i].split(":");

            if (keyValuePermission.length == 2) {
                var accessLevel = keyValuePermission[1].toString();

                if (accessLevel.includes(AccessRight._VIEW_PERMISSION)) {
                    permissionArrKey.push({ activity: keyValuePermission[0], accessLevel: accessLevel.split("") });
                }
            }
        }

        //first filter away non use children
        for (var i = 0; i < menuToUse.length; i++) {
            if (menuToUse[i].children) {
                menuToUse[i].children = menuToUse[i].children.filter(item => {
                    let permission = item['permission'];
                    let role = item['role'];
                    let hasPermission = true;

                    if (permission !== undefined) {
                        hasPermission = permissionArrKey.some(i => i.activity == permission.activity && i.accessLevel.some(i => permission.accessLevel.includes(i)));
                    }

                    if (hasPermission && role !== undefined) {
                        hasPermission = role.some(i => i == _userData.userRoleId);
                    }

                    return hasPermission;
                });
            }
        }

        //secondly filter away parent
        menuToUse = menuToUse.filter(item => {
            if ((!item.children || item.children.length == 0) && item.isDummy) {
                return false;
            }

            let permission = item.permission;
            let role = item.role;

            let hasPermission = true;

            if (permission !== undefined) {
                hasPermission = permissionArrKey.some(i => i.activity == permission.activity && i.accessLevel.some(i => permission.accessLevel.includes(i)));
            }

            if (hasPermission && role !== undefined) {
                hasPermission = role.some(i => i == _userData.userRoleId);
            }

            return hasPermission;
        });

        setFilteredMenu(menuToUse);
    }

    /// <summary>
    /// Author: Saitama
    /// </summary>
    function logoutHandler() {
        showMessage({
            type: AlertTypes._WARNING,
            content: t("PROCEED_LOGOUT_ALERT"),
            showCancel: true,
            confirmBtnText: t("LOGOUT"),
            onConfirm: () => {
                _dispatch(performLogout());
            },
        });
    }

    /// <summary>
    /// Author: Saitama
    /// </summary>
    function openCollapseMenu(menuTitle) {
        if (menuTitle === targetCollapseMenu) {
            setTargetCollapseMenu('');
        }
        else {
            setTargetCollapseMenu(menuTitle);
        }
    }

    /// <summary>
    /// Author: Rock
    /// </summary>
    const submitChangePassword = async (data, e) => {
        try {
            if (data['newPassword'] != data['confirmPassword']) {
                showMessage({ type: AlertTypes._ERROR, content: t("CONFIRM_PASSWORD_AND_PASSWORD_IS_NOT_TALLY") });
                return;
            }

            let params = {
                'oldPassword': data['oldPassword'],
                'newPassword': data['newPassword']
            }

            var responseJson;
            responseJson = await ApiEngine.post(ApiUrl._API_CHANGE_PASSWORD, params);

            if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
                throw responseJson[ApiKey._API_MESSAGE_KEY];
            }
            else {
                showMessage({
                    type: AlertTypes._SUCCESS,
                    content: t(responseJson[ApiKey._API_MESSAGE_KEY]),
                    onConfirm: () => setChangePassword(false)
                })
            }
        }
        catch (err) {
            showMessage({
                type: AlertTypes._ERROR,
                content: t(err)
            })
        }
    }

    return (
        <>
            <Navbar hiddenBreakpoint="sm" hidden={!opened} width={{ sm: 200, lg: 270 }} style={{ backgroundColor: '#e8ebef' }}>
                <Navbar.Section>
                    <Text className="header-lg" fw={700} fz='lg'>
                        Mercury Pro
                        {
                            _userData?.userRoleId == Role._AGENT &&
                            <div className='sidebar-headercreditlimit'>
                                <h5>{t("CREDIT_LIMIT")}: {_userData ? numberWithCurrencyFormat(_userData.creditLimit) : 0}</h5>
                            </div>
                        }
                    </Text>
                </Navbar.Section>
                <Navbar.Section grow>
                    {
                        filteredMenu.map((menu, parentIndex) => {
                            return (
                                _userData.userId != menu.temporaryDisableFor &&
                                <Group mb='xs'>
                                    {
                                        menu.isDummy ?
                                            <>
                                                <UnstyledButton
                                                    onClick={() => {
                                                        openCollapseMenu(menu.title);
                                                    }}
                                                    sx={(theme) => ({
                                                        display: "block",
                                                        width: "100%",
                                                        padding: theme.spacing.xs,
                                                        borderTopRightRadius: theme.radius.xl,
                                                        borderBottomRightRadius: theme.radius.xl,
                                                        "&:hover": {
                                                            backgroundColor: 'rgba(58, 53, 65, 0.04)'
                                                        },
                                                    })}>
                                                    <Group>
                                                        <ActionIcon size="lg" color="dark">
                                                            {menu.icon}
                                                        </ActionIcon>
                                                        <Text ml='sm' mr='xl' fw={700} size="sm">{t(menu.title)}</Text>
                                                        {targetCollapseMenu === menu.title ? <ChevronDown size={20} /> : <ChevronRight size={20} />}
                                                    </Group>
                                                </UnstyledButton>
                                                <Collapse animateOpacity in={targetCollapseMenu === menu.title} style={{ width: '-webkit-fill-available' }}>
                                                    {
                                                        menu.children.map((childMenu, childIndex) => {
                                                            return (
                                                                <Group ml='xl'>
                                                                    <Link
                                                                        to={childMenu.path}
                                                                        className={decodeURI(_currentLocation.pathname) === childMenu.path && 'active-menu'}
                                                                        key={childIndex}
                                                                        onClick={() => {
                                                                            toogle();
                                                                        }}
                                                                        sx={(theme) => ({
                                                                            display: "block",
                                                                            width: "100%",
                                                                            padding: theme.spacing.xs,
                                                                            borderTopRightRadius: theme.radius.lg,
                                                                            borderBottomRightRadius: theme.radius.lg,
                                                                            backgroundColor:
                                                                                decodeURI(_currentLocation.pathname) === childMenu.path
                                                                                    ? '#3aafa9'
                                                                                    : "transparent",
                                                                            "&:hover": {
                                                                                backgroundColor: 'rgba(58, 53, 65, 0.04)'
                                                                            },
                                                                        })}
                                                                    >
                                                                        <Text fw={700} c={decodeURI(_currentLocation.pathname) === childMenu.path
                                                                            ? 'white'
                                                                            : "black"} size="sm">{t(childMenu.title)}</Text>
                                                                    </Link>
                                                                </Group>
                                                            )
                                                        })
                                                    }
                                                </Collapse>
                                            </> :
                                            <Link
                                                to={menu.path}
                                                className={decodeURI(_currentLocation.pathname) === menu.path && 'active-menu'}
                                                key={parentIndex}
                                                onClick={() => {
                                                    openCollapseMenu('');
                                                    toogle();
                                                }}
                                                sx={(theme) => ({
                                                    display: "block",
                                                    width: "100%",
                                                    padding: theme.spacing.xs,
                                                    borderTopRightRadius: theme.radius.xl,
                                                    borderBottomRightRadius: theme.radius.xl,
                                                    backgroundColor:
                                                        decodeURI(_currentLocation.pathname) === menu.path
                                                            ? '#3aafa9'
                                                            : "transparent",
                                                    "&:hover": {
                                                        backgroundColor: 'rgba(58, 53, 65, 0.04)'
                                                    },
                                                })}>
                                                <Group>
                                                    <ActionIcon size="lg" color={decodeURI(_currentLocation.pathname) === menu.path ? 'gray.0' : 'dark'}>
                                                        {menu.icon}
                                                    </ActionIcon>
                                                    <Text ml='sm' fw={700} color={decodeURI(_currentLocation.pathname) === menu.path ? 'white' : 'black'} size="sm">{t(menu.title)}</Text>
                                                </Group>
                                            </Link>
                                    }
                                </Group>
                            )
                        })
                    }
                </Navbar.Section>
                <Divider mb={10} />
                <Navbar.Section px='md' py='xs' className="navbar-footer">
                    <Popover width={280} position="top" withArrow shadow="md">
                        <Popover.Target>
                            <Group>
                                <Box sx={{ flex: 1 }}>
                                    <Text fw={700} tt="uppercase">
                                        {_userData?.username}
                                    </Text>
                                    <Text color="dimmed" size="xs">
                                        {
                                            t(_userData.userRoleId == Role._SUPER_ADMIN ? 'SUPER_ADMIN' : _userData.userRoleId == Role._COMPANY ? 'COMPANY' : _userData.userRoleId == Role._ADMIN ? 'ADMIN' : _userData.userRoleId == Role._AGENT ? 'AGENT' : _userData.userRoleId == Role._MEMBER ? 'MEMBER' : '')
                                        }
                                    </Text>
                                </Box>
                                <Settings />
                            </Group>
                        </Popover.Target>
                        <Popover.Dropdown>
                            <Stack>
                                <Group position="apart">
                                    {
                                        _LANGUAGE_OPTION.map((item) => {
                                            return (
                                                <img className="langSelectionImage" src={item.image}
                                                    style={
                                                        item.value == localStorage.getItem(SessionKey._LANGUAGE) ?
                                                            { 'filter': 'grayscale(0%)', 'borderWidth': '3px', 'borderColor': '#087e15' } : {}
                                                    }
                                                    onClick={() => { onChangeLanguage(item.value) }} />
                                            )
                                        })
                                    }
                                </Group>
                                <Divider />
                                <UnstyledButton style={{ width: "-webkit-fill-available", alignItems: '' }} onClick={() => { setChangePassword(true) }}>
                                    <Group>
                                        <Password />
                                        {t("CHANGE_PASSWORD")}
                                    </Group>
                                </UnstyledButton>
                                <Divider />
                                <UnstyledButton style={{ width: "-webkit-fill-available" }} onClick={() => logoutHandler()}>
                                    <Group>
                                        <Logout />
                                        {t("LOGOUT")}
                                    </Group>
                                </UnstyledButton>
                            </Stack>
                        </Popover.Dropdown>
                    </Popover>
                </Navbar.Section>
                <Modal centered isOpen={changePassword} onClosed={() => { setChangePassword(false) }}>
                    <ModalHeader
                        toggle={() => {
                            setChangePassword(false);
                        }}
                    >
                        {t("CHANGE_PASSWORD")}
                    </ModalHeader>
                    {
                        <ModalBody>
                            <form onSubmit={handleSubmit(submitChangePassword)}>
                                <div className="row">
                                    <div className="col-lg-12">
                                        <Controller
                                            control={control}
                                            name={'oldPassword'}
                                            rules={{ required: true }}
                                            render={({ onChange, value, name }) => (
                                                <Input
                                                    type="password"
                                                    name={name}
                                                    defaultValue={''}
                                                    value={value}
                                                    onChange={value => {
                                                        onChange(value);
                                                    }}
                                                    label={t('OLD_PASSWORD')}
                                                    errors={errors}
                                                />
                                            )}
                                        />
                                    </div>
                                    <div className="col-lg-12">
                                        <Controller
                                            control={control}
                                            name={'newPassword'}
                                            rules={{ required: true }}
                                            render={({ onChange, value, name }) => (
                                                <Input
                                                    type="password"
                                                    name={name}
                                                    defaultValue={''}
                                                    value={value}
                                                    onChange={value => {
                                                        onChange(value);
                                                    }}
                                                    label={t('NEW_PASSWORD')}
                                                    errors={errors}
                                                />
                                            )}
                                        />
                                    </div>
                                    <div className="col-lg-12">
                                        <Controller
                                            control={control}
                                            name={'confirmPassword'}
                                            rules={{ required: true }}
                                            render={({ onChange, value, name }) => (
                                                <Input
                                                    type="password"
                                                    name={name}
                                                    defaultValue={''}
                                                    value={value}
                                                    onChange={value => {
                                                        onChange(value);
                                                    }}
                                                    label={t('CONFIRM_PASSWORD')}
                                                    errors={errors}
                                                />
                                            )}
                                        />
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-lg-3'>
                                        <Button type="submit">{t('SUBMIT')}</Button>
                                    </div>
                                </div>
                            </form>
                        </ModalBody>
                    }
                </Modal>
            </Navbar>
        </>
    );
};

export default Sidebar;